import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { analyticsId, branch, debug, isMobile, isSafari, isInstalled } from '../config'

const EVENT_TYPES = {
    ERROR_LOGIN_UCFSERVER: { category: 'error', action: 'error_connect_to_ucfhere_server' },
    ERROR_LOGIN_AUTOMATIC_VALIDATE: { category: 'error', action: 'error_automatic_validate_token' },
    ERROR_LOGIN_AUTHORIZE: { category: 'error', action: 'error_canvas_authorize' },
    ERROR_LOGIN_VALIDATE: { category: 'error', action: 'error_validate_token' },
    ERROR_LOGIN_TIME_OUT: { category: 'error', action: 'error_time_out' },
    ERROR_CHECK_IN_NO_CAMERA: { category: 'error', action: 'error_no_camera' },
    ERROR_CHECK_IN_REJECTED: { category: 'error', action: 'error_codes_rejected' },
    ERROR_HISTORY_CONNECTION: { category: 'error', action: 'error_history_connection'},
    SIGN_IN: { category: 'sign_in', action: 'sign_in_success' },
    SIGN_IN_AUTOMATIC: { category: 'sign_in', action: 'sign_in_skip_with_cookie_success'},
    CHECK_IN: { category: 'check_in', action: 'check_in_success' },
    HISTORY: { category: 'history', action: 'request_history_success' },
    HISTORY_SECTION: { category: 'history', action: 'request_history_section_success' },
    SIGN_OUT: { category: 'sign_out', action: 'sign_out_success' },
}

const InitializeAnalytics = () => {
    ReactGA.initialize(analyticsId)
}

const FireAnalyticsEvent = (eventType = { category: '', action: '' }, label = '') => {
    const { category, action } = eventType

    if( category === '' || action === '' ){
        // console.log('Invalid analytics event')
        return
    }

    const isError = (category === 'error')
    const branchLabel = branch + '_' + (isInstalled ? 'pwa_' : (isMobile ? 'mobile_browser_' : 'pc_browser_')) + (isSafari ? 'safari' : 'android') + (label !== '' ? '_' : '') + label

    if(debug) {
        console.log('DEBUG MODE: NOT Firing Analytics/Sentry Event: ' + category + ' : ' + action + ' : ' + branchLabel)
        return
    }

    if(isError) {
        Sentry.captureMessage(action)
    }

    const eventData = {
        category: category,
        action: action,
        label: branchLabel
    }
    ReactGA.event(eventData)
}

export { EVENT_TYPES, InitializeAnalytics, FireAnalyticsEvent }