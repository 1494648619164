import React from 'react'

import './history-attendance-item.scss'

/**
 * This component is a single entry in the HistoryList
 * It shows the data from a single check-in instance
 */

export function AttendanceItem(props) {
  const {
    timeStamp,
    state,
    graded,
    override,
    DICT
  } = props

  // eslint-disable-next-line
  const HISTORY_STATES = DICT.HISTORY.HISTORY_STATES
  const days = DICT.HISTORY.DAYS

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  const time = new Date(timeStamp).toLocaleString(DICT.HISTORY.region, options)
  const dateAndState = DICT.HISTORY.itemLabel1 + ' ' + HISTORY_STATES[state] + ' ' + DICT.HISTORY.itemLabel2 + ' ' + time + ' ' + DICT.HISTORY.itemLabel3
    + (!graded ? '. ' + DICT.HISTORY.notGraded + '.' : '')
    + (override ? '. ' + DICT.HISTORY.overridden + '.' : '')

  return (
    <article className='History-List-Item wide-content-wrapper flex-r flex-space' tabIndex='0' aria-label={dateAndState}>
      <section aria-hidden='true' className='flex-c full-width'>
        <article aria-hidden='true' className='flex-r full-width flex-space'>
          <article aria-hidden='true' className='List-Item-Title'>{new Date(timeStamp).toLocaleString(DICT.HISTORY.region)} ({days[new Date(timeStamp).getDay()]})</article>
          <article aria-hidden='true' className='List-Item-Title History-Item-State'><span aria-hidden='true' className={`bold stateClass${state.toString()}`}>{HISTORY_STATES[state]}</span></article>
        </article>
        <article aria-hidden='true' className='List-Item-Subtitle'>{DICT.HISTORY.graded}: {graded ? DICT.HISTORY.yes : DICT.HISTORY.no} {override ? (<span aria-hidden='true' className='italic'>&nbsp;&nbsp;*{DICT.HISTORY.override}*</span>) : ''}</article>
      </section>
    </article>
  )
}
