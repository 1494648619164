import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SELECT_KEYS } from '../../util'
import { useAuth } from '../../util/useAuth'
import { EVENT_TYPES, FireAnalyticsEvent } from '../../util/analytics'
import { authAPI } from '../../api/auth'
import { branch, BRANCHES, debug, version } from '../../config'

import { Loader } from '../../components/loader/loader'
import logo from '../../assets/UCF_Here_Icon.png'

import './login.scss'

/**
 * This screen allows the user to log in into UCF Here
 * It sends them to Webcourses to log in
 */

export function LoginScreen({ DICT }) {
    const navigate = useNavigate()
    const { user, clearCookies, generateSessionToken } = useAuth()

    const currentUrl = window.location.href
    const searchParams = new URL(currentUrl).searchParams
    const errorCode = searchParams.get('errorCode')
    const errorCodes = DICT.LOGIN.ERROR

    let message = ''
    if (errorCode) {
        if (errorCodes[parseInt(errorCode)]) {
            message = errorCodes[parseInt(errorCode)]
        }
        else {
            // Changes codes like 'invalid_request' to 'Invalid Request' by replacing
            // the underscores with spaces, then capitalizing the first letter of each word
            const codeString = errorCode
                .toString()
                .replace(/_/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
            message = DICT.LOGIN.unexpectedError + ': ' + codeString
        }
    }

    const [errorMessage, setErrorMessage] = useState(message)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [isCheckingToken, setIsCheckingToken] = useState(user?.token ? true : false)

    // STEP 0: CHECK FOR EXISTING TOKENS
    // When there is an existing user (cookie), check to see if it has a valid token.
    // If so, skip the whole login process! Possibly redundant with loginLayout.
    useEffect(() => {
        let successfulLogin = false

        const timedOutTimer = setInterval(() => {
            timedOutTimer && clearInterval(timedOutTimer)
            if(isCheckingToken) {
                FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_TIME_OUT)
                setIsCheckingToken(false)
            }
        }, 3000)

        const attemptAutoLogin = async () => {
            const token = user?.token
            if (token) {
                try {
                    const res = await authAPI.checkToken(token)
                    successfulLogin = res.valid
                } catch (error) {
                    console.error(error)
                    clearCookies()
                } finally {
                    timedOutTimer && clearInterval(timedOutTimer)
                    if (successfulLogin) {
                        FireAnalyticsEvent(EVENT_TYPES.SIGN_IN_AUTOMATIC)
                        navigate('/auth/checkin')
                    }
                    else {
                        // If there is a token, but it's not valid, then clear out all cookies.
                        FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_AUTOMATIC_VALIDATE)
                        setIsCheckingToken(false)
                        clearCookies()
                    }
                }
            }
            else {
                setIsCheckingToken(false)
            }
        }

        attemptAutoLogin()
    })

    // STEP 1: CONTACT UCF HERE SERVER
    // When a user clicks the SIGN IN button, the first step is to contact the UCF Here server.
    // If the server is up, it will send us the URL that needs to be used to authenticate.
    const beginLogIn = async () => {
        setErrorMessage('')
        setIsLoggingIn(true)

        let isServerUp = false
        let authURL
        let getURLResponse

        try {
            getURLResponse = await authAPI.getURL()

            if (getURLResponse['url'] || 'url' in getURLResponse) {
                authURL = getURLResponse['url']
                isServerUp = true
            }

        } catch (error) {
            console.error(error)
        } finally {
            if (debug) {
                navigate('/login')
            }
            else {
                if (isServerUp) {
                    redirect(authURL)
                } else {
                    FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_UCFSERVER, (getURLResponse.status ? getURLResponse.status.toString() : 'unknownStatus') + (getURLResponse.message ? getURLResponse.message : 'noMessage'))
                    setErrorMessage(DICT.LOGIN.noResponse)
                    setIsLoggingIn(false)
                    clearCookies()
                }
            }
        }
    }

    // STEP 2: Redirect to the SSO page at webcourses.ucf.edu (prod) or canvas.dev.cdl.ucf.edu (dev)
    const redirect = (url) => {
        const tempToken = generateSessionToken(12)
        const uri = url + `&state=` + tempToken + `&redirect_uri=${authAPI.createRedirectUri()}`
        window.location.replace(uri)
    }

    const signInLabel = (isCheckingToken || isLoggingIn)
        ? DICT.LOADER.connect
        : DICT.MAIN.signIn

    return (
        <div className='Login-container'>
            <div className='flex-r flex-center full-width full-height'>
                <div className='Login-body-container flex-c'>
                    <div className='Login-body'>
                        <img src={logo} className='Login-logo' alt={DICT.LOGIN.logoAlt} title={DICT.LOGIN.logoAlt}></img>
                        <div className='Login-text flex-c'>
                            <div className='Login-UCF'>{DICT.LOGIN.UCF}</div>
                            <div className='Login-Here'>{DICT.LOGIN.here}</div>
                        </div>
                    </div>
                    <div tabIndex={errorMessage ? '0' : '-1'} className='Login-Error'>{errorMessage}</div>
                </div>
                <div className='Login-Button-container' role='button' aria-label={signInLabel} tabIndex='0' onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key) && !isCheckingToken && !isLoggingIn) { beginLogIn() } }} >
                    {isCheckingToken
                        ? (<Loader DICT={DICT} color='black' message={DICT.LOADER.token}></Loader>)
                        : isLoggingIn
                            ? (<Loader DICT={DICT} color='black' message={DICT.LOADER.connect}></Loader>)
                            : (<button tabIndex='-1' aria-hidden='true' className='button-rounded' onClick={() => beginLogIn()}>{DICT.LOGIN.signIn}</button>)}
                </div>
                <div className='Login-Version'>
                    {`${DICT.LOGIN.version} ${version}
                        ${debug
                            ? `- ${DICT.LOGIN.local.toUpperCase()}`
                            : branch === BRANCHES.DEV
                                ? `- ${DICT.LOGIN.qa.toUpperCase()}`
                                : ''}`}
                </div>
            </div>
        </div>
    )
}
