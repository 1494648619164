import React from 'react'

import './toggle.scss'

/* Toggle Component adapted from https://www.sitepoint.com/react-toggle-switch-reusable-component/ */

export function Toggle ({id, name, toggle, value = false }) {
    return (
        <div className="toggle-switch">
            <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={name}
            id={id}
            checked={value}
            tabIndex='0'
            onChange = {() => { toggle() }}
            />
            <label className="toggle-switch-label" htmlFor={id}>
                <span className="toggle-switch-inner" />
                <span className="toggle-switch-switch" />
            </label>
        </div>
    )
}