import { ReactComponent as ProgressIcon } from '../../assets/progress-bar--round.svg'

import './loader.scss'

const COLOR_BLACK = 'black'

/**
 * This component is used when waiting for data
 */
// Valid colors are 'black', 'gold', and 'white'
export function Loader ({DICT, color = COLOR_BLACK, message}) {

    if(!message || message === ''){
        message = DICT.LOADER.default
    }

    return (
        <div className={`Loader flex-r flex-center ${color}`}>
            <div className='Spinner flex-c flex-center'>
                <ProgressIcon className='icon'/>
            </div>
            <div className='flex-c flex-center'>
                <div role='heading' aria-level='2' className='Loader-text'>{message}</div>
            </div>
        </div>
    )
}
