import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { AuthProvider } from './util/useAuth'
import { branch, sentry_dsn, version } from './config'

import { App } from './App'

import './index.css'

Sentry.init({
  attachStacktrace: true,
  dsn: sentry_dsn,
  environment: branch,
  release: version,
  sampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root'))

// The BrowserRouter enables specfic routing endpoints, like /login, or /here/history
// The AuthProvider provides context for whether the user is logged in or not.
root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
