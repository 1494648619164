import React from 'react'

import { SCAN_RESPONSE, SELECT_KEYS } from '../../util'

import { ReactComponent as CircleEmpty } from '../../assets/circle-stroke.svg'
import { ReactComponent as CircleFilled } from '../../assets/circle-fill.svg'
import { ReactComponent as CircleCheck } from '../../assets/checkmark--filled.svg'
import { ReactComponent as CircleError } from '../../assets/close--filled.svg'
import { ReactComponent as CameraRotate } from '../../assets/camera-rotate.svg'

import './status-bar.scss'

/**
 * This component is used to show status when you can the QR code
 */

export function StatusBar ({DICT, firstScan, secondScan, scanResponse, transparent=false, switchCamera}) {

    const statusDescription = (
        firstScan === '' | secondScan === '' | scanResponse === ''
            ? DICT.CHECKIN.helperText1
            : scanResponse === SCAN_RESPONSE.SUCCESS
                ? DICT.CHECKIN.success
                : DICT.CHECKIN.fail )

    return (
        <>
            { scanResponse === ''
                ? ( <div role='button' aria-label={DICT.CHECKIN.cameraBtnAlt} tabIndex='0' className='Camera-Button-Container' onKeyDown={(event) => { if(SELECT_KEYS.includes(event.key)) { switchCamera() }}}  >
                        <div className='Camera-Button' alt={DICT.CHECKIN.cameraBtnAlt} title={DICT.CHECKIN.cameraBtnAlt} onClick={() => { switchCamera() }}>
                            <CameraRotate fill='black' src={CameraRotate} />
                        </div>
                    </div> )
                : ''
            }
            <div className='StatusBar-Container centered'>
                <div aria-label={statusDescription} tabIndex='0' className={`StatusBar${transparent ? ' transparent' : ''}`}>
                    <div className='StatusIcon'>
                        { firstScan === ''
                            ? (<CircleEmpty role='img' className='white' alt={DICT.CHECKIN.scan1Wait} title={DICT.CHECKIN.scan1Wait} />)
                            : (<CircleFilled className='green' alt={DICT.CHECKIN.scan1Complete} title={DICT.CHECKIN.scan1Complete} />)
                        }
                    </div>
                    <div className='StatusIcon'>
                        { secondScan === ''
                            ? (<CircleEmpty className='white' alt={DICT.CHECKIN.scan2Wait} title={DICT.CHECKIN.scan2Wait} />)
                            : (<CircleFilled className='green' alt={DICT.CHECKIN.scan2Complete} title={DICT.CHECKIN.scan2Complete} />)
                        }
                    </div>
                    <div className='StatusIcon'>
                        { scanResponse === ''
                            ? (<CircleEmpty fill='white' alt={DICT.CHECKIN.responseWait} title={DICT.CHECKIN.responseWait} />)
                            : scanResponse === SCAN_RESPONSE.SUCCESS
                                ? (<CircleCheck className='green' src={CircleCheck} alt={DICT.CHECKIN.success} title={DICT.CHECKIN.success} />)
                                : (<CircleError className='red' src={CircleError} alt={DICT.CHECKIN.fail} title={DICT.CHECKIN.fail} />)
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
