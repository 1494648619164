import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import { FONT_CLASSES, DEFAULT_FONT_SIZE, DEFAULT_DARK_MODE_ON } from './util'
import { InitializeAnalytics } from './util/analytics'
import { useAuth } from './util/useAuth'
import { useLocalStorage } from './util/useLocalStorage'
import { LANG, LANGUAGES, DEFAULT_LANGUAGE } from './util/language'

import { LoginLayout } from './layouts/loginLayout'
import { PageLayout } from './layouts/pageLayout'

import { LoginScreen } from './screens/login/login'
import { ValidateScreen } from './screens/validate/validate'
import { CheckInScreen } from './screens/check-in/check-in'
import { HistoryScreen } from './screens/history/history'
import { ProfileScreen } from './screens/profile/profile'
import { HistorySectionScreen } from './screens/history-section/history-section-screen'

import './App.scss'

// In index.js, the <App /> element is wrapped in an AuthProvider, which provides
// context for the current user and their state. This context is used in the
// LoginLayout and PageLayout elements to automatically redirect users to the
// appropriate pages. Not logged in? Go to the /login page. Logged in? Go to the
// /here/checkin page.

export function App() {
  const { user } = useAuth()
  const [language, setLanguage] = useLocalStorage('language', DEFAULT_LANGUAGE)
  const [fontSize, setFontSize] = useLocalStorage('fontSize', DEFAULT_FONT_SIZE)
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', DEFAULT_DARK_MODE_ON)
  const [DICT, setDICT] = useState(LANG[language] || LANG[DEFAULT_LANGUAGE])
  const [isTall, setIsTall] = useState(window.innerHeight > window.innerWidth)

  // There are major style changes between portrait and landscape modes, which is applied as a class
  // in the PageLayout component based on the isTall value. When the screen rotates, this function
  // needs to be called to check that variable.
  const handleResize = () => {
    setIsTall(window.innerHeight > window.innerWidth)
  }

  useEffect(() => {
    InitializeAnalytics()

    // This is sufficient for most Android devices, but not Apple.
    window.addEventListener('resize', handleResize)

    // After testing, it was found that regardless of what other precautions and checks were used,
    // iPhones and iPads would not consistently update their layout when rotated.
    // So we replaced all the clever logic with a manual check 4x per second (quick enough to act
    // by the tail end of the screen rotation animation)
    const sizeTimer = setInterval(() => {
      handleResize()
    }, 250);

    // This runs when the component unmounts, preventing memory leaks and duplicate timers.
    return () => {
      clearInterval(sizeTimer);
    };
  },
  // eslint-disable-next-line
  [])

  // This is called when a user changes the Language setting in the profile screen.
  const changeLanguage = (newLanguage) => {
    if (LANGUAGES.includes(newLanguage)) {
      setLanguage(newLanguage)
    }
    if (LANG[newLanguage]) {
      setDICT(LANG[newLanguage])
    }
  }

  // This is called when a user changes the Font Size setting in the profile screen.
  const changeFontSize = (size) => {
    const sizeString = parseInt(size).toString()
    if (FONT_CLASSES[sizeString]) {
      // Save the selected size in cookies for the future.
      setFontSize(sizeString)
    }
  }

  // This is called when a user changes the Dark Mode setting in the profile screen.
  const toggleDarkMode = () => {
    const newMode = !darkMode
    setDarkMode(newMode)
  }

  // Notice that the current DICTionary is passed to every component, since text elements are rendered there.
  // However, things like fontSize and darkMode are passed to the Layout components, where additional classes
  // are added applied via the CSS, and aren't needed in each individual component.

  // The big exception is the profile screen, where all the settings are viewed and changed.
  return (
    <Routes>
      <Route element={<LoginLayout isTall={isTall} fontSize={fontSize} darkMode={darkMode} />}>
        <Route path='*' element={<LoginScreen DICT={DICT} />} />
        <Route path='/' element={<LoginScreen DICT={DICT} />} />
        <Route path='/login' element={<ValidateScreen DICT={DICT} />} />
      </Route>
      <Route path='/auth' element={<PageLayout isTall={isTall} fontSize={fontSize} darkMode={darkMode} />}>
        <Route path='checkin' element={<CheckInScreen token={user?.token} DICT={DICT} />} />
        <Route path='history' element={<HistoryScreen DICT={DICT} />} />
        <Route path='history/:sectionId' element={<HistorySectionScreen DICT={DICT} />} />
        <Route path='profile' element={
          <ProfileScreen
            DICT={DICT}
            language={language}
            changeLanguage={changeLanguage}
            fontSize={fontSize}
            changeFontSize={changeFontSize}
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode} />} />
      </Route>
    </Routes>
  )
}
