import { useEffect } from 'react'

import { DECREMENT_KEYS, INCREMENT_KEYS, SCREEN_PROFILE, SELECT_KEYS } from '../../util'
import { useAuth } from '../../util/useAuth'
import { LANGUAGES } from '../../util/language'
import { EVENT_TYPES, FireAnalyticsEvent } from '../../util/analytics'
import { isInstalled, version } from '../../config'

import { FooterNav } from '../../components/footer-nav/footer-nav'
import { Toggle } from '../../components/toggle/toggle'

import { ReactComponent as OptionsChrome } from '../../assets/options--chrome.svg'
import { ReactComponent as OptionsSafari } from '../../assets/options--safari.svg'
import { ReactComponent as CaretDown } from '../../assets/caret--down.svg'

import './profile.scss'


/**
 * This screen shows the user profile
 * It shows user information and instructions for support
 */

export function ProfileScreen({ DICT, language, changeLanguage, fontSize, changeFontSize, darkMode, toggleDarkMode }) {
    const { logout, user } = useAuth()

    // All of the other components simply inherit the DICTionary variable, but in this
    // component, we want it to be a local state so there is an instant update when
    // the language is changed.

    const handleSignOut = () => {
        FireAnalyticsEvent(EVENT_TYPES.SIGN_OUT)
        logout()
    }

    // This function is called when the user uses the left or right arrows to change the language.
    const changeLanguageWithKey = (key) => {
        if (!INCREMENT_KEYS.includes(key) && !DECREMENT_KEYS.includes(key)) {
            return
        }

        // Error handling: Make sure that the current language is in the list.
        let currentLanguageIndex = -1
        for (let i = 0; i < LANGUAGES.length; i++) {
            if (LANGUAGES[i] === language) {
                currentLanguageIndex = i
            }
        }
        if (currentLanguageIndex === -1) { return }

        // Cycle one forward or backwards in the list.
        let nextLanguageIndex = currentLanguageIndex
        if (INCREMENT_KEYS.includes(key)) {
            nextLanguageIndex++
            if (nextLanguageIndex > (LANGUAGES.length - 1)) {
                nextLanguageIndex = 0
            }
        }
        if (DECREMENT_KEYS.includes(key)) {
            nextLanguageIndex--
            if (nextLanguageIndex < 0) {
                nextLanguageIndex = LANGUAGES.length - 1
            }
        }

        changeLanguage(LANGUAGES[nextLanguageIndex])
    }

    const changeFontSizeWithKey = (key) => {
        const currentSize = parseInt(fontSize)
        if (INCREMENT_KEYS.includes(key) && currentSize < 5) {
            changeFontSize(currentSize + 1)
        }
        if (DECREMENT_KEYS.includes(key) && currentSize > 1) {
            changeFontSize(currentSize - 1)
        }
    }

    useEffect(() => {
        const el = document.getElementById('assistive-notification')
        if (el) el.innerHTML = DICT.PROFILE.welcome
    },
        /* eslint-disable react-hooks/exhaustive-deps */
        [])

    return (
        <div className='FullScreen'>
            <div id='assistive-notification' className='Reader-Instructions' aria-live='polite'></div>
            <div className='FullContent'>
                <div className='Profile-container PrimaryScreen wide-content-wrapper'>
                    <div className='flex-c flex-space full-width'>
                        <div className='flex-c flex-stiff'>
                            {user.avatar_url && user.avatar_url !== ''
                                ? (<div className='image-container full-width'>
                                    <div><img className='profile-img' alt={DICT.PROFILE.avatarAlt} title={DICT.PROFILE.avatarAlt} src={user.avatar_url} /></div>
                                    <div><h2>{user.name || DICT.PROFILE.noName}</h2></div>
                                </div>)
                                : (<div className='flex-r flex-center full-width'>
                                    <h2>{user.name || DICT.PROFILE.noName}</h2>
                                </div>)}
                        </div>
                        <div className='flex-c flex-grow'>
                            <div className={`Notice-container ${DICT.DIRECTION || 'ltr'}`}>
                                {isInstalled
                                    ? ('')
                                    : (<div>
                                        {DICT.PROFILE.instructions1}<br /><br />
                                        {DICT.PROFILE.instructions2}<div className='inline-icon'><OptionsChrome className='icon' title={DICT.PROFILE.chromeAlt} /></div> {DICT.PROFILE.instructions3} <div className='inline-icon'><OptionsSafari className='icon' title={DICT.PROFILE.safariAlt} /></div>{DICT.PROFILE.instructions4}
                                        <br /><br /><br />
                                    </div>)
                                }
                                <div>
                                    {DICT.PROFILE.techSupport1} <a className='bold' href='mailto:webcourses@ucf.edu'>Webcourses@UCF</a> {DICT.PROFILE.techSupport2}<br /><br />
                                </div>
                                <div className='version'>
                                    {DICT.LOGIN.UCF + ' ' + DICT.LOGIN.here + ' ' + DICT.LOGIN.version + ' ' + version}
                                </div>
                            </div>
                            <div className='setting-container'>
                                <div
                                    tabIndex='0'
                                    aria-label={DICT.PROFILE.languageLabel1 + ' ' + language + ' ' + DICT.PROFILE.languageLabel2}
                                    onKeyDown={(event) => { changeLanguageWithKey(event.key) }}
                                    className='flex-r flex-space'
                                >
                                    <div className='flex-c flex-center'>
                                        <label className='settingLabel' htmlFor='language-selector'>{DICT.PROFILE.language}</label>
                                    </div>
                                    <div className='flex-r flex-end'>
                                        <select id='language-selector' tabIndex='-1' value={language} onChange={(e) => changeLanguage(e.target.value)}>
                                            {LANGUAGES.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                        <CaretDown alt='' title='' className='icon gold dropdown-icon' />
                                    </div>
                                </div>
                            </div>

                            <div className='setting-container'>
                                <div
                                    tabIndex='0'
                                    aria-label={DICT.PROFILE.fontSizeLabel1 + ' ' + fontSize + ' ' + DICT.PROFILE.fontSizeLabel2}
                                    onKeyDown={(event) => { changeFontSizeWithKey(event.key) }}
                                    className='flex-r flex-space'
                                >
                                    <div className='flex-c flex-center'>
                                        <label className='settingLabel' htmlFor='font-size-selector'>{DICT.PROFILE.fontSize}</label>
                                    </div>
                                    <div className='flex-c flex-center'>
                                        <div className='scale-container'>
                                            <input id='font-size-selector' tabIndex='-1' type='range' min='1' max='5' step='1' className='slider' onChange={(e) => changeFontSize(e.target.value)} value={fontSize} />
                                        </div>
                                        <div className='scale-container size-samples flex-r flex-space'>
                                            <div className='flex-c flex-center font-size-smaller size-sample'>A</div>
                                            <div className='flex-c flex-center font-size-normal size-sample'>A</div>
                                            <div className='flex-c flex-center font-size-larger size-sample'>A</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='setting-container'>
                                <div
                                    tabIndex='0'
                                    aria-label={(darkMode ? DICT.PROFILE.darkModeLabelOn : DICT.PROFILE.darkModeLabelOff) + ' ' + DICT.PROFILE.darkModeLabel2}
                                    onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key)) { toggleDarkMode() } }}
                                    className='flex-r flex-space'
                                >
                                    <div className='flex-c flex-center'>
                                        <label className='settingLabel' htmlFor='dark-mode-toggle'>{DICT.PROFILE.darkMode}</label>
                                    </div>
                                    <div className='flex-c flex-center'>
                                        <Toggle tabIndex='-1' id='dark-mode-toggle' name='darkMode' toggle={() => toggleDarkMode()} value={darkMode} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='flex-c flex-stiff Button-wrapper'>
                            <div role='button' tabIndex='0' onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key)) { handleSignOut() } }} aria-label={DICT.PROFILE.signOut} className='Button-container'>
                                <button tabIndex='-1' className='button-rounded gold' onClick={() => handleSignOut()}>{DICT.PROFILE.signOut}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='FooterNav'>
                <FooterNav activeScreen={SCREEN_PROFILE} DICT={DICT}></FooterNav>
            </div>
        </div>
    )
}
