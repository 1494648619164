import { Outlet } from 'react-router-dom'
import { FONT_CLASSES } from '../util'
import { isInstalled, isSafari, isIPad } from '../config'

export const LoginLayout = ({ isTall, fontSize, darkMode }) => {

    const fontClass = FONT_CLASSES[fontSize]

    // Previously, there was a check for the user cookies here. That is now handled asynchronously
    // on the login.js page along with a database check to see if the token is valid.
  
    return (
        <div id='App' className={`App${(isInstalled && isSafari) || isIPad ? ' extra-padding' : ''} ${fontClass}${darkMode ? ' darkMode' : ''}${isTall === true ? ' tall' : ' wide'}`}>
            <Outlet />
        </div>
    )
}