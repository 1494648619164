import React from 'react'

import { SELECT_KEYS } from '../../util'

import { ReactComponent as ExpandIcon } from '../../assets/chevron--right.svg'

import './history-course-item.scss'

/**
 * This component is a single course entry
 * It shows information about a course
 * Once you click on it, you can see more information on attendance
 */

export function CourseItem (props){
  const {
    sectionId,
    sectionName,
    courseName,
    instructorName,
    selectSection,
    DICT
  } = props

  return (
    <section
      tabIndex='0'
      aria-label={DICT.HISTORY.sectionLabel1 + ' ' + courseName + ' ' + DICT.HISTORY.sectionLabel2 + ' ' + sectionName + ' ' + DICT.HISTORY.sectionLabel3 + ' ' + instructorName + ' ' + DICT.HISTORY.sectionLabel4}
      role='button'
      className='Course-List-Item flex-r flex-space wide-content-wrapper'
      onClick={() => selectSection(sectionId)}
      onKeyDown={(event) => { if(SELECT_KEYS.includes(event.key)) { selectSection(sectionId)}}}
      >
      <article className='flex-c flex-grow'>
        <article aria-label={courseName + ' - ' + sectionName} role='heading' aria-level='3' className='List-Item-Title'>{courseName} - {sectionName}</article>
        <article aria-label={instructorName} role='heading' aria-level='4' className='List-Item-Subtitle'>{instructorName}</article>
      </article>
      <article className='flex-c flex-center flex-stiff'>
        <ExpandIcon role='button' className='icon' title={DICT.HISTORY.viewDetails} />
      </article>
    </section>
  )
}
