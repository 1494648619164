export const SCREEN_CHECK_IN = 'Check In'
export const SCREEN_HISTORY = 'History'
export const SCREEN_PROFILE = 'Profile'

export const SCREENS = [
  SCREEN_CHECK_IN,
  SCREEN_HISTORY,
  SCREEN_PROFILE,
]

export const SCAN_RESPONSE = {
  'ERROR': 'error',
  'SUCCESS': 'success'
}

export const SELECT_KEYS = [
  'Enter',
  ' ',
]

export const DESELECT_KEYS = [
  'Escape',
]

export const DECREMENT_KEYS = [
  'ArrowLeft',
  '-',
]

export const INCREMENT_KEYS = [
  'ArrowRight',
  '+',
]

export const FONT_CLASSES = {
  1: 'font-size-smaller',
  2: 'font-size-small',
  3: 'font-size-normal',
  4: 'font-size-large',
  5: 'font-size-larger',
}

export const DEFAULT_FONT_SIZE = 3

export const DEFAULT_DARK_MODE_ON = false

/**
 * Parse responses from fetch API using .json()
 *
 * @param {object} response - Response object from fetch API
 * @returns {Promise} If the parse was success or not
 */
export function toJSON(response) {
  if (!response.ok) return Promise.reject(response)

  return response.json()
}