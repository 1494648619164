export const version = '1.2.2'

export const BRANCHES = {
  LOCAL: 'local',
  DEV: 'qa',
  PROD: 'prod'
}

export const branch = process.env.REACT_APP_BUILD
export const debug = (branch === BRANCHES.LOCAL)
export const analyticsId = process.env.REACT_APP_ANALYTICS_ID
export const sentry_dsn = process.env.REACT_APP_SENTRY_DSN

export const isInstalled = window.matchMedia('(display-mode: standalone)').matches
export const isMobile = window.matchMedia('(any-pointer:coarse)').matches
// GestureEvent is possibly hacky:
// https://stackoverflow.com/questions/7944460/detect-safari-browser
export const isSafari = !!window.GestureEvent
  || window.navigator.userAgent.match(/iPhone/i)
  || window.navigator.userAgent.match(/iPad/i)
  || window.navigator.userAgent.match(/iPod/i)

export const isIPad = window.navigator.userAgent.match(/iPad/i)
export const zoomStepCount = 16

export const ucfhere_server =
  ( branch === BRANCHES.DEV
    ? 'https://canvas-lti.dev.cdl.ucf.edu/ucfhere'
    : 'https://canvas-lti.cdl.ucf.edu/ucfhere' )

export const app_url_local = 'https://here.qa.cdl.ucf.edu/login'
export const canvas_login_dev = 'https://canvas-lti.dev.cdl.ucf.edu/ucfhere/login/oauth/url'
export const auth_server_dev = 'https://ucf.test.instructure.com/login/oauth2/auth?client_id=11580000000000706&response_type=code'
export const avatar_url_dev = 'https://webcourses.ucf.edu/images/messages/avatar-50.png'
export const auth_token_dev = 'fakeToken'

export const courseData = {
  'sections': [
    {
      'section_id': 31,
      'section_name': '001',
      'course': {
        'course_id': 36,
        'name': 'Underwater Basketweaving',
        'instructor': {
          'name': 'Emanuel Cortés Lugo'
        },
      },
    },
    {
      'section_id': 33,
      'section_name': '002',
      'course': {
        'course_id': 40,
        'name': 'Aboveground Basketweaving',
        'instructor': {
          'name': 'Lugo Cortés Emanuel'
        },
      },
    },
    {
      'section_id': 36,
      'section_name': '003',
      'course': {
        'course_id': 44,
        'name': 'Sky-based Basketweaving',
        'instructor': {
          'name': 'Lugo Cortés Emanuel'
        },
      },
    }
  ]
}

export const historyData = {
  'section_name': 'Current Section',
  'history': [
    {
      'override': false,
      'timestamp': '2012-01-26T13:51:50.417-07:00',
      'state': 0,
      'session': {
        'state': 0, // Graded
      },
    },
    {
      'override': false,
      'timestamp': '2012-01-25T13:51:50.417-07:00',
      'state': 0,
      'session': {
        'state': 0, // Graded
      },
    },
    {
      'override': false,
      'timestamp': '2012-01-24T13:51:50.417-07:00',
      'state': 1,
      'session': {
        'state': 1, // Graded
      },
    },
    {
      'override': true,
      'timestamp': '2012-01-22T13:51:50.417-07:00',
      'state': 2,
      'session': {
        'state': 2, // Graded
      },
    },
    {
      'override': true,
      'timestamp': '2012-01-19T13:51:50.417-07:00',
      'state': 3,
      'session': {
        'state': 3, // Graded
      },
    },
    {
      'override': false,
      'timestamp': '2012-01-17T13:51:50.417-07:00',
      'state': 4,
      'session': {
        'state': 4, // Graded
      },
    },
    {
      'override': false,
      'timestamp': '2012-01-15T13:51:50.417-07:00',
      'state': 0,
      'session': {
        'state': 0, // Graded
      },
    },
  ]
}
