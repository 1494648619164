import { toJSON } from '../util'
import {  app_url_local,
          auth_server_dev,
          avatar_url_dev,
          debug,
          ucfhere_server,
        } from '../config'



/**
 * getURL()
 * 
 * Attempts to fetch the appropriate Canvas oauth2 url from the UCFHere Server.
 *
 * If successful, the response would look like:
 * { 'url': 'https://webcourses.ucf.edu/login/oauth2/auth?client_id=*****************&response_type=code' }
 */
async function getURL() {

  if(debug) { return Promise.resolve({ 'url': auth_server_dev }) }

  const ucfHereServerLoginUrl = `${ucfhere_server}/login/oauth/url`

  try {
    const response = await fetch(ucfHereServerLoginUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
    
    if(response.status === 200){
      const responseJSON = await response.json()
      if(responseJSON['url']) {
        return {
          'status': response.status,
          'url': responseJSON['url']
        }
      }
      else {
        return {
          'status': response.status,
          'message': 'noURLin200Response' + JSON.stringify(responseJSON)
        }  
      }
    }
    else {
      return {
        'status': response.status,
        'message': 'noURLinResponse'
      }
    }

  } catch (error) {
    console.log(error.message)
    return {
      'status': '500',
      'message': error.message + ' from ' + ucfHereServerLoginUrl
    }
  }
}



/**
 * createRedirectUri()
 * 
 * When logging into Canvas, we need to give it a 'redirect_url' so it can return to our site.
 */
function createRedirectUri() {
  let tempUrl = window.location.origin

  // If we're testing or on localhost, manually set the login url
  if(tempUrl.includes('localhost')) {
      return app_url_local
  }

  // Remove the trailing slash (if present) for consistency
  if(tempUrl.endsWith('/')) {
      tempUrl = tempUrl.substring(0, tempUrl.length - 1)
  }

  tempUrl = tempUrl + '/login'

  return tempUrl
}



/**
 * getToken(code)
 * 
 * Using the code returned from Canvas, this attempts to fetch user information
 * and the server token from the UCFHere Server.
 * 
 * If successful, the response would look like:
 * { 'token': 'xxxxxxxxxx',
 *   'id': 'xxxxxxxxxx',
 *   'name': 'User Full Name' }
 */
function getToken(code) {

  const ucfHereServerTokenUrl = `${ucfhere_server}/login/oauth/token`

  const redirect_url = createRedirectUri()

  try {
    return fetch(ucfHereServerTokenUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${code}`
      },
      body: JSON.stringify({ redirect_url })
    }).then(toJSON)
  } catch (error) {
    console.log(error.message)
    return error
  }
}



/**
 * checkToken(token)
 * 
 * Attempts to validate your token with the UCFHere Server. This returns either a 200 (good)
 * or 401 (unauthorized) status in response.
 * 
 * If successful, the response would look like:
 * { 'valid': true }
 */
function checkToken(token) {
  if (!token) return Promise.reject('Token not defined')

  if(debug) { return Promise.resolve({ 'valid': true }) }

  const ucfHereServerCheckTokenUrl = `${ucfhere_server}/login/oauth/checktoken`

  try {
    return fetch(ucfHereServerCheckTokenUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(toJSON)
  } catch (error) {
    console.log(error.message)
    return error
  }
}




/**
 * getAvatar(token)
 * 
 * Attempts to fetch the URL for the user's image from the UCFHere Server.
 *
 * If successful, the response would look like:
 * { 'avatar_url': 'https://webcourses.ucf.edu/images/messages/xxxxxxxxxx.png' }
 */
function getAvatar(token) {
  if(debug) { return Promise.resolve({ 'avatar_url': avatar_url_dev }) }

  const ucfHereServerAvatarUrl = `${ucfhere_server}/api/v1/avatar`

  try {
    return fetch(ucfHereServerAvatarUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(toJSON)
  } catch (error) {
    console.log(error.message)
    return error
  }
}



/**
 * revokeToken(token)
 * 
 * Attempts to remove the session token from the UCFHere Server.
 *
 * If successful, the response would be an empty message ({}) with a 200 status.
 * 
 * If unsuccessful, it could be an other status with a message like this:
 * { 'error': 'Canvas is down' } (400)
 * { 'error': 'Invalid authorization code'} (403)
 */
function revokeToken(token = null) {

  if (debug) {
    return
  }
  
  if (!token) {
    return Promise.reject('Token not defined')
  }

  const ucfHereServerLogoutUrl = `${ucfhere_server}/login/oauth/logout`

  return fetch(ucfHereServerLogoutUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(toJSON)
}

export const authAPI = {
  checkToken,
  createRedirectUri,
  getAvatar,
  getToken,
  getURL,
  revokeToken
}