/**
 * To add an additional language support:
 * 
 * 1) Add the language to the LANGUAGES array. It will then appear on the Profile screen as an option.
 * 2) Copy the entire [LANG_ENGLISH] sub-object inside of LANG and paste it as another subobject at the
 *    end of the LANG object.
 * 3) Change the key of the copied object from LANG_ENGLISH to the new language (it must match whatever
 *    you put in the LANGUAGES array).
 * 4) Translate all of the strings to your new language. If you don't have or know a translation, keep
 *    the English phrase so the text doesn't appear blank.
 */

const LANG_ENGLISH = 'English'
const LANG_SPANISH = 'Español'
const LANG_PORTUGUESE = 'Português'
const LANG_ARABIC = 'العربية'

export const LANGUAGES = [
    LANG_ENGLISH,
    LANG_SPANISH,
    LANG_PORTUGUESE,
    LANG_ARABIC,
]

// const specialCharsSpan = 'á é í ó ú   ü   ñ'
// const specialCharsPort = 'ç   á é í ó ú   â ê ô   ã õ   à ò'

export const DEFAULT_LANGUAGE = LANG_ENGLISH

export const LANG = {
    [LANG_ENGLISH] : {
        'DIRECTION': 'ltr',     // ltr (Left-to-Right) or rtl (Right-to-Left)
        'MAIN' : {
            // Nav buttons and headers
            'checkIn': 'Check In',
            'history': 'History',
            'profile': 'Profile',
        },
        'LOADER': {
            // These are in the expected order that the user will see them
            'connect': 'Connecting...',
            'token': 'Checking Token...',
            'validate': 'Validating...',
            'courses': 'Loading Courses...',
            'history': 'Loading History...',
            'default': 'Loading...',
        },
        'LOGIN' : {
            // Main Login Page. Since UCF Here is the product name, consider keeping
            // these two in English.
            'UCF': 'UCF',
            'here': 'Here',
            'logoAlt': 'UCF Here logo',
            'signIn': 'Sign In',
            'version': 'Version',
            'local': 'Local Testing',
            'qa': 'QA Branch',

            // There are a number of possible error messages. The ERROR object should
            // keep the same keys, since these are from the database.
            'unexpectedError': 'Unexpected Error',
            'noResponse': 'The UCF Here Server did not respond. Please try again.',
            'ERROR': {
                1: 'The server took too long to respond. Please try again.',
                2: 'Account is not authorized. Please try again.',
                3: 'Your session has expired. Please sign in again.',
                4: 'Unable to validate session. Please try again.',
                5: 'Invalid authorization code. Please try again.'
            }
        },
        'CHECKIN' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'You are now on the Check In page. Scan your instructor\'s QR code to be marked present.',
            
            // This is the text at the top of the check-in screen to help guide users
            'helperText1': 'Scanning for QR Codes...',
            'helperText2': 'Please hold your device steady...',

            // Mouseover text for the camera button
            'cameraBtnAlt': 'Switch Camera',

            // Mouseover text for the three-circle indicators based on state
            'scan1Wait': 'Awaiting First Scan...',
            'scan1Complete': 'First Scan Complete',
            'scan2Wait': 'Awaiting Second Scan...',
            'scan2Complete': 'Second Scan Complete',
            'responseWait': 'Awaiting Response...',
            'success': 'Scan successful',
            'fail': 'Scan failed',

            'zoomIn': 'Zoom In',
            'zoomOut': 'Zoom Out',
        },
        'FEEDBACK' : {
            'headerSuccess': 'Success',
            'headerError': 'Error',

            // Specific messages based on server codes. 100 and 200 are after SUCCESS
            'message100': 'You have already been marked Present.',
            'message200': 'You have been marked Present.',
            'message300': 'There was an unacceptable amount of time between your two scans. Please try again.',
            'message404': 'You are not enrolled in this section.',
            'messageDefault': 'We were unable to successfully record your attendance. Please try again.',

            // These labels are to show specific feedback for how your attendance was marked.
            'courseLabel': 'Course',
            'instructorLabel': 'Instructor',
            'timeLabel': 'Time',

            // The OK button appears after a success, and sends you to History.
            // The Try Again button appears after an error, and sends you back to Check In.
            'btnOK': 'OK',
            'btnTryAgain': 'Try Again',
        },
        'HISTORY' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'You are now on the History page. This page lists all of your courses that use UCF Here.',
            
            // The screen welcome message in history-section-screen is formed using the following strings:
            // sectionWelcome1 + courseName + sectionWelcome2 + sectionName + sectionWelcome3
            // In English, this would render as:
            // 'You are now viewing ' + courseName + ' section ' + sectionName + ' attendance history'
            'sectionWelcome1': 'You are now viewing',
            'sectionWelcome2': 'section',
            'sectionWelcome3': 'attendance history',

            // The aria-label in history-section-screen has a description formed using the following strings:
            // sectionLabel1 + courseName + sectionLabel2 + sectionName + sectionLabel3 + instructorName + sectionLabel4
            // In English, this would render as:
            // '' + courseName + ' section ' + sectionName + ' with ' + instructorName + ''
            'sectionLabel1': '',
            'sectionLabel2': 'section',
            'sectionLabel3': 'with',
            'sectionLabel4': '',

            // The mouseover text for the arrow at the right of each course in the list
            'viewDetails': 'View Details',

            // The aria-label in history-attendance-item.js is formed using the following strings:
            // itemLabel1 + HISTORY_STATE + itemLabel2 + timestamp + itemLabel3 + (notGraded || overridden || '')
            // In English, we don't need any additional text before or after the phrase, so it reads as:
            // '' + 'Tardy' + 'on' + 'Wednesday, September, 2023 at 3:05 PM'
            'itemLabel1': '',
            'itemLabel2': 'on',
            'itemLabel3': '',
            'notGraded': 'This is not yet graded',
            'overridden': 'This grade was overridden by your instructor',

            // Text that appears on screen as headers, messages, or buttons
            'allCourses': 'All Courses',
            'noHistory': 'No History Found',
            'noSection': 'No Section Found',
            'refreshCourses': 'Refresh Course List',
            'refreshHistory': 'Refresh History',

            // The upper-left "back" button and its description
            'coursesBtnLabel': 'Courses',
            'coursesBtnDesc': 'Back to Courses',

            // Text that appears as part of the individual history items
            'graded': 'In Gradebook',
            'yes': 'Yes',
            'no': 'No',
            'override': 'Instructor Override',

            // Affects the way the date is formatted: a list of valid options is at
            // https://www.w3schools.com/jsref/jsref_tolocalestring.asp
            'region': 'en-us',

            // Keep DAYS in order, with Sunday as the 0 index
            'DAYS': ['Sun', 'Mon', 'Tue', 'Wed', 'Th', 'Fri', 'Sat'],

            // The server sends data from 0 to 4 that correspond exactly to the states
            // below. They must be kept in order with the corresponding indices.
            'HISTORY_STATES': {
                0: 'Present',
                1: 'Absent',
                2: 'Excused',
                3: 'Excluded',
                4: 'Tardy',
            },
        },
        'PROFILE' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'You are now on the Profile page. This page allows you to toggle settings and sign out.',

            // This is the default if the user name was not pulled successfully from Canvas
            'noName': 'No Name',

            // The instructions that appear in the main screen area. Icons for Chrome and Safari
            // are drawn after instructions2 and instructions3, respectively.
            'instructions1': 'To add this app to your phone\'s home screen:',
            'instructions2': 'Click the overflow icon (',
            'instructions3': 'or',
            'instructions4': '), then "Add to Home Screen"',

            // The email link for Webcourses@UCF is in between these two strings.
            'techSupport1': 'For technical support, please contact ',
            'techSupport2': '',

            // Image alt text
            'avatarAlt': 'User Avatar',
            'chromeAlt': 'Chrome Options Icon',
            'safariAlt': 'Safari Options Icon',

            // The Labels are for screenreaders, with the current language in between.
            'language': 'Language',
            'languageLabel1': 'Current language is ',
            'languageLabel2': '. Use left and right arrows to change.',

            // The Labels are for screenreaders, with the current font size in between.
            'fontSize': 'Font Size',
            'fontSizeLabel1': 'Font size',
            'fontSizeLabel2': 'of 5. Use left and right arrows to change.',

            // The Labels are for screenreaders, based on state.
            'darkMode': 'Dark Mode',
            'darkModeLabelOn': 'Dark Mode is on.',
            'darkModeLabelOff': 'Dark Mode is on.',
            'darkModeLabel2': 'Press space to toggle.',

            'signOut': 'Sign Out',
        }
    },
    [LANG_SPANISH] : {
        'DIRECTION': 'ltr',
        'MAIN' : {
            // Nav buttons and headers
            'checkIn': 'Escanear',
            'history': 'Registros',
            'profile': 'Perfil',
        },
        'LOADER': {
            // These are in the expected order that the user will see them
            'connect': 'Conectando...',
            'token': 'Validando...',
            'validate': 'Validando...',
            'courses': 'Buscando Cursos...',
            'history': 'Buscando Registros...',
            'default': 'Cargando...',
        },
        'LOGIN' : {
            // Main Login Page. Since UCF Here is the product name, consider keeping
            // these two in English.
            'UCF': 'UCF',
            'here': 'Here',
            'logoAlt': 'Logo de UCF Here',
            'signIn': 'Iniciar Sesión',
            'version': 'Versión',
            'local': 'Ensayo Local',
            'qa': 'Rama QA',

            // There are a number of possible error messages. The ERROR object should
            // keep the same keys, since these are from the database.
            'unexpectedError': 'Error Desconocido',
            'noResponse': 'El servidor no pudo ser localizado. Por favor intenta de nuevo.',
            'ERROR': {
                1: 'El servidor tomó demasiado tiempo. Por favor intenta de nuevo.',
                2: 'El usuario no está autorizado. Por favor intenta de nuevo.',
                3: 'La sesión expiró. Por favor inicia tu sesión de nuevo.',
                4: 'La sesión no fue validada. Por favor intenta de nuevo.',
                5: 'El código de autorización no es válido. Por favor intenta de nuevo.',
            }
        },
        'CHECKIN' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Estás en la página donde puedes escanear el código QR para ser marcado presente.',
            
            // This is the text at the top of the check-in screen to help guide users
            'helperText1': 'Buscando códigos QR...',
            'helperText2': 'Por favor mantén tu dispositivo fijo...',

            // Mouseover text for the camera button
            'cameraBtnAlt': 'Rotar Cámara',

            // Mouseover text for the three-circle indicators based on state
            'scan1Wait': 'Esperando por el Primer Código...',
            'scan1Complete': 'Primer Código Escaneado',
            'scan2Wait': 'Esperando por el Segundo Código...',
            'scan2Complete': 'Segundo Código Escaneado',
            'responseWait': 'Esperando Respuesta...',
            'success': 'Éxito',
            'fail': 'Escán ha Fallado',

            'zoomIn': 'Aumentar Zoom',
            'zoomOut': 'Disminuir Zoom',
        },
        'FEEDBACK' : {
            'headerSuccess': 'Éxito',
            'headerError': 'Error',

            // Specific messages based on server codes. 100 and 200 are after SUCCESS
            'message100': 'Ya has sido marcado/a presente.',
            'message200': 'Has sido marcado/a presente.',
            'message300': 'Hubo un inaceptable cantidad de tiempo entre tus escanes. Por favor intenta de nuevo.',
            'message404': 'No estás inscrito/a en esta sección.',
            'messageDefault': 'No pudimos marcar tu asistencia exitosamente. Por favor intenta de nuevo.',

            // These labels are to show specific feedback for how your attendance was marked.
            'courseLabel': 'Curso',
            'instructorLabel': 'Instructor',
            'timeLabel': 'Hora',

            // The OK button appears after a success, and sends you to History.
            // The Try Again button appears after an error, and sends you back to Check In.
            'btnOK': 'OK',
            'btnTryAgain': 'Intentar de Nuevo',
        },
        'HISTORY' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Estás en la página de Registros. Ésta página contiene todos sus cursos que utilizan UCF Here.',
            
            // The screen welcome message in history-section-screen is formed using the following strings:
            // sectionWelcome1 + courseName + sectionWelcome2 + sectionName + sectionWelcome3
            // In English, this would render as:
            // 'You are now viewing ' + courseName + ' section ' + sectionName + ' attendance history'
            'sectionWelcome1': 'Está en la página de registros del curso',
            'sectionWelcome2': 'sección',
            'sectionWelcome3': '.',

            // The aria-label in history-section-screen has a description formed using the following strings:
            // sectionLabel1 + courseName + sectionLabel2 + sectionName + sectionLabel3 + instructorName + sectionLabel4
            // In English, this would render as:
            // '' + courseName + ' section ' + sectionName + ' with ' + instructorName + ''
            'sectionLabel1': '',
            'sectionLabel2': 'sección',
            'sectionLabel3': 'con',
            'sectionLabel4': '',

            // The mouseover text for the arrow at the right of each course in the list
            'viewDetails': 'Ver más detalles',

                    // const specialCharsSpan = 'á é í ó ú   ü   ñ'
            // The aria-label in history-attendance-item.js is formed using the following strings:
            // itemLabel1 + HISTORY_STATE + itemLabel2 + timestamp + itemLabel3 + (notGraded || overridden || '')
            // In English, we don't need any additional text before or after the phrase, so it reads as:
            // '' + 'Tardy' + 'on' + 'Wednesday, September, 2023 at 3:05 PM'
            'itemLabel1': '',
            'itemLabel2': 'el día',
            'itemLabel3': '',
            'notGraded': 'Esto no ha sido procesado.',
            'overridden': 'La asistencia fue cambiada por su instructor.',

            // Text that appears on screen as headers, messages, or buttons
            'allCourses': 'Cursos',
            'noHistory': 'No Hay Registros',
            'noSection': 'No Hay Sección',
            'refreshCourses': 'Actualizar Cursos',
            'refreshHistory': 'Actualizar Registros',

            // The upper-left "back" button and its description
            'coursesBtnLabel': 'Cursos',
            'coursesBtnDesc': 'Regresar',

            // Text that appears as part of the individual history items
            'graded': 'Procesado',
            'yes': 'Sí',
            'no': 'No',
            'override': 'Cambiado por Instructor',

            // Affects the way the date is formatted: a list of valid options is at
            // https://www.w3schools.com/jsref/jsref_tolocalestring.asp
            'region': 'es-mx',

            // Keep DAYS in order, with Sunday as the 0 index
            'DAYS': ['Dom', 'Lun', 'Mar', 'Miérc', 'Juev', 'Vier', 'Sáb'],

            // The server sends data from 0 to 4 that correspond exactly to the states
            // below. They must be kept in order with the corresponding indices.
            'HISTORY_STATES': {
                0: 'Presente',
                1: 'Ausente',
                2: 'Excusado',
                3: 'Excluido',
                4: 'Tarde',
            },
        },
        'PROFILE' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Está en la página de Perfil. Ésta página te permite cambiar las configuraciones y cerrar la sesión.',

            // This is the default if the user name was not pulled successfully from Canvas
            'noName': 'No Nombre',

            // The instructions that appear in the main screen area. Icons for Chrome and Safari
            // are drawn after instructions2 and instructions3, respectively.
            'instructions1': 'Para añadir el app a su móbil:',
            'instructions2': 'Preciona el icono (',
            'instructions3': 'o',
            'instructions4': '), después "Añade a la Pantalla Principal"',
            
            // The email link for Webcourses@UCF is in between these two strings.
            'techSupport1': 'Para ayuda técnica, por favor contacta ',
            'techSupport2': '',

            // Image alt text
            'avatarAlt': 'Imagen del Usuario',
            'chromeAlt': 'Icono de Configuración de Chrome',
            'safariAlt': 'Icono de Compartir de Safari',
            
            // The Labels are for screenreaders, with the current language in between.
            'language': 'Idioma',
            'languageLabel1': 'Idioma actual es ',
            'languageLabel2': '. Para cambiar el idioma, usa las teclas de flecha izquierda o derecha.',

            // The Labels are for screenreaders, with the current font size in between.
            'fontSize': 'Tamaño de Letra',
            'fontSizeLabel1': 'Tamaño de letra actual es',
            'fontSizeLabel2': 'de 5. Para cambiar el tamaño, usa las teclas de flecha izquierda o derecha.',

            // The Labels are for screenreaders, based on state.
            'darkMode': 'Modo Oscuro',
            'darkModeLabelOn': 'El modo oscuro está activado.',
            'darkModeLabelOff': 'El modo oscuro está desactivado.',
            'darkModeLabel2': 'Presione la barra espaciadora para cambiar.',

            'signOut': 'Cerrar Sesión',
        }
    },
    [LANG_PORTUGUESE] : {
        'DIRECTION': 'ltr',
        'MAIN' : {
            // Nav buttons and headers
            'checkIn': 'Escanear',
            'history': 'Histórico' ,
            'profile': 'Perfil', // or Usuário
        },
        'LOADER': {
            // These are in the expected order that the user will see them
            'connect': 'Conectando…',
            'token': 'Checando Token...',
            'validate': 'Validando…',
            'courses': 'Carregando aulas…',
            'history': 'Carregando histórico da aula..',
            'default': 'Carregando…',
        },
        'LOGIN' : {
            // Main Login Page. Since UCF Here is the product name, consider keeping
            // these two in English.
            'UCF': 'UCF',
            'here': 'Here',
            'logoAlt': 'UCF Here logo',
            'signIn': 'Entrar',
            'version': 'Versão',
            'local': 'Teste local',
            'qa': 'Branch de QA',
            // There are a number of possible error messages. The ERROR object should
            // keep the same keys, since these are from the database.
            'unexpectedError': 'Erro Inesperado',
            'noResponse': 'O servidor de UCF Here não respondeu. Por favor tente novamente.',
            'ERROR': {
                1: 'O servidor demorou muito para responder. Por favor tente novamente.',
                2: 'Conta não autorizada. Por favor tente novamente.',
                3: 'Sua sessão expirou. Por favor entre novamente.',
                4: 'Incapaz de validar sessão. Por favor tente novamente.',
                5: 'Código de autorização inválido. Por favor tente novamente.'
            }
        },
        'CHECKIN' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Você está agora na página de Escanear QR Code. Escaneie o QR code do see instructor para ser marcado como presente.',
            // This is the text at the top of the check-in screen to help guide users
            'helperText1': 'Escaneando QR Codes...',
            'helperText2': 'Por favor estabilize seu dispositivo...',
            // Mouseover text for the camera button
            'cameraBtnAlt': 'Alternar Câmera',
            // Mouseover text for the three-circle indicators based on state
            'scan1Wait': 'Esperando primeiro escaneio…',
            'scan1Complete': 'Primeiro escaneio completado',
            'scan2Wait': 'Esperando segundo escaneio…',
            'scan2Complete': 'Segundo escaneio completado',
            'responseWait': 'Esperando resposta…',
            'success': 'Escaneio feito com sucesso',
            'fail': 'Falha no escaneio',

            'zoomIn': 'Aumentar Zoom',
            'zoomOut': 'Diminuir Zoom',
        },
        'FEEDBACK' : {
            'headerSuccess': 'Sucesso',
            'headerError': 'Erro',
    
            // Specific messages based on server codes. 100 and 200 are after SUCCESS
            'message100': 'Você já foi marcado como Presente.',
            'message200': 'Você foi marcado como Presente.',
            'message300': 'Houve um atraso inaceitável entre o momento em que os códigos QR foram gerados e o envio do seus escaneies. Por favor, tente novamente.',
            'message404': 'Você não está matriculado nessa sessão.',
            'messageDefault': 'Não foi possível registrar sua presença com sucesso. Por favor, tente novamente.',
    
            // These labels are to show specific feedback for how your attendance was marked.
            'courseLabel': 'Aula',
            'instructorLabel': 'Instrutor',
            'timeLabel': 'Horário',
    
            // The OK button appears after a success, and sends you to History.
            // The Try Again button appears after an error, and sends you back to Check In.
            'btnOK': 'OK',
            'btnTryAgain': 'Tente Novamente',
        },
        'HISTORY' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Você está agora na página de Histórico de aulas. Essa página lista todas as suas aulas que usam UCF Here.',
            // The screen welcome message in history-section-screen is formed using the following strings:
            // sectionWelcome1 + courseName + sectionWelcome2 + sectionName + sectionWelcome3
            // In English, this would render as:
            // 'You are now viewing ' + courseName + ' section ' + sectionName + ' attendance history'
            'sectionWelcome1': 'Você está agora vendo',
            'sectionWelcome2': 'sessão',
            'sectionWelcome3': 'histórico de presença.',
            // The aria-label in history-section-screen has a description formed using the following strings:
            // sectionLabel1 + courseName + sectionLabel2 + sectionName + sectionLabel3 + instructorName + sectionLabel4
            // In English, this would render as:
            // '' + courseName + ' section ' + sectionName + ' with ' + instructorName + ''
            'sectionLabel1': 'Aula',
            'sectionLabel2': 'sessão',
            'sectionLabel3': 'com',
            'sectionLabel4': '',
            // The mouseover text for the arrow at the right of each course in the list
            'viewDetails': 'Ver os detalhes',
            // The aria-label in history-attendance-item.js is formed using the following strings:
            // itemLabel1 + HISTORY_STATE + itemLabel2 + timestamp + itemLabel3 + (notGraded || overridden || '')
            // In English, we don't need any additional text before or after the phrase, so it reads as:
            // '' + 'Tardy' + 'on' + 'Wednesday, September, 2023 at 3:05 PM'
            'itemLabel1': '',
            'itemLabel2': 'em',
            'itemLabel3': '',
            'notGraded': 'Não foi avaliado ainda',
            'overridden': 'Essa nota foi editada pelo seu instructor',
            // Text that appears on screen as headers, messages, or buttons
            'allCourses': 'Todas as aulas',
            'noHistory': 'Histórico Não Encontrado',
            'noSection': 'Sessão Não Encontrada',
            'refreshCourses': 'Refrescar Lista de Aulas',
            'refreshHistory': 'Refrescar Histórico de Aulas',
            // The upper-left "back" button and its description
            'coursesBtnLabel': 'Aulas',
            'coursesBtnDesc': 'Voltar para Aulas',
            // Text that appears as part of the individual history items
            'graded': 'Avaliado',
            'yes': 'Sim',
            'no': 'Não',
            'override': 'Editado pelo Instrutor',
            // Affects the way the date is formatted: a list of valid options is at
            // https://www.w3schools.com/jsref/jsref_tolocalestring.asp
            'region': 'pt-br',
            // Keep DAYS in order, with Sunday as the 0 index
            'DAYS': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            // The server sends data from 0 to 4 that correspond exactly to the states
            // below. They must be kept in order with the corresponding indices.
            'HISTORY_STATES': {
                0: 'Presente',
                1: 'Ausente',
                2: 'Falta Justificada',
                3: 'Anulado',
                4: 'Atrasado',
            },
        },
        'PROFILE' : {
            // An audio-only cue for screenreaders when the page is entered
            'welcome': 'Você está agora na página de Perfil do Usuário. Essa página permits question você mode configurações e deslogue-se.',
            // This is the default if the user name was not pulled successfully from Canvas
            'noName': 'Sem Nome',
            // The instructions that appear in the main screen area. Icons for Chrome and Safari
            // are drawn after instructions2 and instructions3, respectively.
            'instructions1': 'Para colocar esse aplicativo na sua tela inicial:',
            'instructions2': 'Clique no ícone indicado (',
            'instructions3': 'ou',
            'instructions4': '), e então “Adicionar a Tela Inicial”',
            // The email link for Webcourses@UCF is in between these two strings.
            'techSupport1': 'Para suporte técnico, por favor contate ',
            'techSupport2': '',
            // Image alt text
            'avatarAlt': 'Avatar do Usuário',
            'chromeAlt': 'Ícone de Opções do Chrome',
            'safariAlt': 'Ícone de Opções do Safari',
            // The Labels are for screenreaders, with the current language in between.
            'language': 'Idioma',
            'languageLabel1': 'Idioma atual é ',
            'languageLabel2': '. Use as teclas de seta esquerda e directs para mudar.',
            // The Labels are for screenreaders, with the current font size in between.
            'fontSize': 'Tamanho da Fonte',
            'fontSizeLabel1': 'Tamanho da fonte',
            'fontSizeLabel2': 'de 5. Use as teclas de seta esquerda e directs para mudar.',
            // The Labels are for screenreaders, based on state.
            'darkMode': 'Modo Noturno',
            'darkModeLabelOn': 'Modo Noturno Ativado.',
            'darkModeLabelOff': 'Modo Noturno Desativado.',
            'darkModeLabel2': ' Pressione espaço para ativar.',
    
            'signOut': 'Deslogar',
        }
    },   
    [LANG_ARABIC]: {
        'DIRECTION': 'rtl',
        "MAIN": {
            "checkIn": "تسجيل حضور",
            "history": "التاريخ",
            "profile": "الملف الشخصي"
        },
        "LOADER": {
            "connect": "...جارٍ الاتصال",
            "token": "...جار التحقق من الرمز",
            "validate": "...جارٍ التحقق",
            "courses": "...جارٍ تحميل الدورات",
            "history": "...جارٍ تحميل التاريخ",
            "default": "...جارٍ التحميل"
        },
        "LOGIN": {
            "UCF": "UCF",
            "here": "Here",
            "logoAlt": "شعار UCF Here",
            "signIn": "تسجيل الدخول",
            "version": "الإصدار",
            "local": "اختبار محلي",
            "qa": "فرع الاختبار",
            "unexpectedError": "خطأ غير متوقع",
            "noResponse": "لم يستجب UCF Here, يرجى المحاولة مرة اخرى",
            "ERROR": {
                "1": ".استغرق الخادم وقتًا طويلًا للرد. يرجى المحاولة مرة أخرى",
                "2": ".الحساب غير مصرح به. يرجى المحاولة مرة أخرى",
                "3": ".انتهت صلاحية جلستك. يرجى تسجيل الدخول مرة أخرى",
                "4": ".تعذر التحقق من الجلسة. يرجى المحاولة مرة أخرى",
                "5": ".رمز التفويض غير صالح. يرجى المحاولة مرة أخرى"
            }
        },
        "CHECKIN": {
            "welcome": ".أنت الآن في صفحة تسجيل الحضور. قم بمسح رمز الاستجابة السريعة لمعلمك لتسجيل الحضور",
            "helperText1": "...جارٍ البحث عن رموز الاستجابة السريعة",
            "helperText2": "...يرجى تثبيت الجهاز",
            "cameraBtnAlt": "تغيير الكاميرا",
            "scan1Wait": "...في انتظار المسح الأول",
            "scan1Complete": "تم المسح الأول",
            "scan2Wait": "...في انتظار المسح الثاني",
            "scan2Complete": "تم المسح الثاني",
            "responseWait": "...في انتظار الرد",
            "success": "تم المسح بنجاح",
            "fail": "فشل المسح",

            'zoomIn': 'تكبير الشاشة',
            'zoomOut': 'تصغير الشاشة',
        },
        "FEEDBACK": {
            "headerSuccess": "نجاح",
            "headerError": "خطأ",
            "message100": ".انت حضورك مسجل اساساً",
            "message200": ".تم تسجيل حضورك",
            "message300": ".كان هناك وقت غير مقبول بين المسحتين. يرجى المحاولة مرة أخرى",
            "message404": ".أنت غير مسجل في هذا القسم",
            "messageDefault": ".تعذر تسجيل حضورك بنجاح. يرجى المحاولة مرة أخرى",
            "courseLabel": "الدورة",
            "instructorLabel": "المعلم",
            "timeLabel": "الوقت",
            "btnOK": "حسنًا",
            "btnTryAgain": "حاول مجددًا"
        },
        "HISTORY": {
            "welcome": ".UCF Here أنت الآن في صفحة التاريخ. تُظهر هذه الصفحة جميع الدورات التي تستخدم",
            "sectionWelcome1": "أنت الآن تشاهد ",
            "sectionWelcome2": "القسم",
            "sectionWelcome3": ".تاريخ الحضور",
            "sectionLabel1": "",
            "sectionLabel2": "القسم",
            "sectionLabel3": "مع",
            "sectionLabel4": "",
            "viewDetails": "تفاصيل العرض",
            "itemLabel1": "",
            "itemLabel2": "في",
            "itemLabel3": "",
            "notGraded": ".لم يتم التقييم بعد .",
            "overridden": ".تم تجاوز الدرجة من قبل المعلم .",
            "allCourses": "جميع الدورات",
            "noHistory": "لا يوجد تاريخ",
            "noSection": "لا يوجد قسم",
            "refreshCourses": "تحديث قائمة الدورات",
            "refreshHistory": "تحديث التاريخ",
            "coursesBtnLabel": "الدورات",
            "coursesBtnDesc": "العودة إلى الدورات",
            "graded": "في سجل الدرجات",
            "yes": "نعم",
            "no": "لا",
            "override": "تجاوز المعلم",
            "region": "en-us",
            "DAYS": ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],
            'HISTORY_STATES': {
                0: "حضر",
                1: "غاب",
                2: "معذور",
                3: "مستبعد",
                4: "تأخر",
            },
        },
        'PROFILE' : {
            'welcome': '.أنت الآن في صفحة الملف الشخصي. تتيح لك هذه الصفحة تبديل الإعدادات وتسجيل الخروج',
            'noName': 'لا يوجد اسم',
            'instructions1': ':لإضافة هذا التطبيق إلى الشاشة الرئيسية لهاتفك',
            'instructions2': 'انقر على أيقونة الفائضة (',
            'instructions3': 'أو',
            'instructions4': ')، ثم "Add To Home Screen"',
            'techSupport1': 'للحصول على الدعم التقني، يرجى الاتصال بـ ',
            'techSupport2': '',
            'avatarAlt': 'صورة المستخدم',
            'chromeAlt': 'أيقونة خيارات كروم',
            'safariAlt': 'أيقونة خيارات سفاري',
            'language': 'اللغة',
            'languageLabel1': 'اللغة الحالية هي ',
            'languageLabel2': '.استخدم الأسهم اليسرى واليمنى للتغيير .',
            'fontSize': 'حجم الخط',
            'fontSizeLabel1': 'حجم الخط',
            'fontSizeLabel2': '.من 5. استخدم الأسهم اليسرى واليمنى للتغيير',
            'darkMode': 'وضع اللون الداكن',
            'darkModeLabelOn': '.وضع اللون الداكن مفعل',
            'darkModeLabelOff': '.وضع اللون الداكن معطل',
            'darkModeLabel2': '.اضغط المسافة للتبديل',
            'signOut': 'تسجيل الخروج',
        }
    } 
}