import { Navigate, Outlet } from 'react-router-dom'
import { FONT_CLASSES } from '../util'
import { useAuth } from '../util/useAuth'
import { isIPad, isInstalled, isSafari } from '../config'

export const PageLayout = ({ isTall, fontSize, darkMode }) => {
    const { user } = useAuth()

    // If the user is not logged in, send them to the main page to log in.
    if (!user) {
      return <Navigate to='/' />
    }

    const fontClass = FONT_CLASSES[fontSize]

    return (
        <div id='App' className={`App${(isInstalled && isSafari) || isIPad ? ' extra-padding' : ''} ${fontClass} ${darkMode ? ' darkMode' : ''} ${isTall === true ? ' tall' : ' wide'}`}>
            <Outlet isTall />
        </div>
    )
}
