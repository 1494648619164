import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SCREEN_HISTORY, SELECT_KEYS } from '../../util'
import { useAuth } from '../../util/useAuth'
import { EVENT_TYPES, FireAnalyticsEvent } from '../../util/analytics'
import { useLocalStorage } from '../../util/useLocalStorage'
import { historyAPI } from '../../api/history'

import { Loader } from '../../components/loader/loader'
import { CourseItem } from '../../components/history-course-item/history-course-item'
import { FooterNav } from '../../components/footer-nav/footer-nav'

import './history.scss'

/**
 * This screen contains course information
 * It shows a list of all the courses the user has taken that use UCF Here
 */

export function HistoryScreen({ DICT }) {
    const { user, logout } = useAuth()
    const navigate = useNavigate()
    const [isCoursesLoading, setIsCoursesLoading] = useState(true)
    // Sections are set here, but read in the history-section-screen
    // eslint-disable-next-line
    const [sections, setSections] = useLocalStorage('sections', null)

    const loadCourses = async () => {
        setIsCoursesLoading(true)
        try {
            const response = await historyAPI.getSections(user.token)
            if (!response || !response.sections || response.sections.length === 0) {
                setSections(null)
            }
            else {
                FireAnalyticsEvent(EVENT_TYPES.HISTORY)
                setSections(response.sections)
            }
            setIsCoursesLoading(false)
        } catch (error) {
            FireAnalyticsEvent(EVENT_TYPES.ERROR_HISTORY_CONNECTION)
            console.error(error)
            setIsCoursesLoading(false)
            logout()
        }
    }

    const selectSection = (sectionId) => {
        navigate(`/auth/history/${sectionId}`)
    }

    useEffect(() => {
        if (sections != null) {
            setIsCoursesLoading(false)
        }
    }, [sections])

    useEffect(() => {
        if (!user.token) {
            logout()
            return
        }

        try {
            if (!sections) {
                loadCourses()
            }
        } catch (error) {
            console.error(error)
        }

        const el = document.getElementById('assistive-notification')
        if (el) el.innerHTML = DICT.HISTORY.welcome
    },
        /* eslint-disable react-hooks/exhaustive-deps */
        [])

    return (
        <div className='FullScreen'>
            <div id='assistive-notification' className='Reader-Instructions' aria-live='polite'></div>
            <div className='FullContent'>
                <main className='History-container PrimaryScreen'>
                    <section className='flex-c full-width'>
                        <header tabIndex='0' className='List-Header'>
                            <article role='heading' aria-level='2' aria-label={DICT.HISTORY.allCourses} className='List-Header-Title wide-content-wrapper'>{DICT.HISTORY.allCourses}</article>
                        </header>
                        {(isCoursesLoading)
                            ? (<article className='Loader-container disabled'><Loader DICT={DICT} message={DICT.LOADER.courses} color='black' /></article>)
                            : <>
                                {/* Without data, the list is empty */
                                    (!sections || sections.length === 0)
                                        ? (<article aria-label={DICT.HISTORY.noHistory} role='heading' aria-level='2' className='Loader-container'><div className='flex-r flex-center'>{DICT.HISTORY.noHistory}</div></article>)
                                        /* With course data, render a list */
                                        : (<>
                                            {sections.map((section, index) => {
                                                return (
                                                    <CourseItem
                                                        key={index}
                                                        sectionId={section.section_id}
                                                        sectionName={section.section_name}
                                                        courseName={section.course.name}
                                                        instructorName={section.course.instructor.name}
                                                        selectSection={selectSection}
                                                        DICT={DICT}
                                                    />
                                                )
                                            })}
                                        </>
                                        )
                                }
                            </>
                        }
                    </section>
                    {(!isCoursesLoading)
                        ? (<article role='button' tabIndex='0' aria-label={DICT.HISTORY.refreshCourses} className='Button-container' onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key)) { loadCourses() } }}><button tabIndex='-1' className='button-rounded gold' onClick={() => loadCourses()}>{DICT.HISTORY.refreshCourses}</button></article>)
                        : ''
                    }
                </main>
            </div>
            <footer className='FooterNav'>
                <FooterNav activeScreen={SCREEN_HISTORY} DICT={DICT}></FooterNav>
            </footer>
        </div>
    )
}
