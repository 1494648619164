import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../util/useAuth'
import { EVENT_TYPES, FireAnalyticsEvent } from '../../util/analytics'
import { useLocalStorage } from '../../util/useLocalStorage'
import { authAPI } from '../../api/auth'
import { avatar_url_dev, branch, BRANCHES, debug, version } from '../../config'

import { Loader } from '../../components/loader/loader'
import logo from '../../assets/UCF_Here_Icon.png'

import '../login/login.scss'

/**
 * This screen validates user login. It is navigated to from Canvas after being authorized.
 */
export function ValidateScreen ({ DICT }) {
    const { login, sessionToken } = useAuth()
    const navigate = useNavigate()

    const [isValidated, setIsValidated] = useState(false)
    // When a user first logs in, delete the previously downloaded course data to make sure it's fresh.
    // eslint-disable-next-line
    const [sections, setSections] = useLocalStorage('sections', null)

    useEffect(() => {

        const attemptValidation = async () => {
            const currentUrl = window.location.href
            const searchParams = new URL(currentUrl).searchParams
            const code = searchParams.get('code')
            const stateToken = searchParams.get('state')
            const passedError = searchParams.get('error')
            if (code) {
                if( !stateToken || !sessionToken || stateToken !== sessionToken ) {
                    console.error('MISMATCHING SESSION TOKENS')
                    FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_VALIDATE)
                    navigate('/?errorCode=4')
                    return
                }
                const tokenResponse = await authAPI.getToken(code)
                if(tokenResponse['error']) {
                    console.error('AUTHORIZATION TOKEN INVALID')
                    FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_VALIDATE)
                    navigate('/?errorCode=5')
                    return
                }
                const { token, id, name } = tokenResponse
                timedOutTimer && clearInterval(timedOutTimer)
                FireAnalyticsEvent(EVENT_TYPES.SIGN_IN)
                let avatar_url = ''
                try {
                    const avatar_response = await authAPI.getAvatar(token)
                    avatar_url = avatar_response?.avatar_url || ''
                } catch (error) {
                    console.warn('COULD NOT FETCH USER AVATAR')
                }
                setIsValidated(true)
                setSections(null)
                login({
                    name: name,
                    id: id,
                    token: token,
                    avatar_url: avatar_url
                })
                return
            }
            else if (passedError) {
                // If there was a login error, go back to the main login screen
                FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_VALIDATE)
                navigate('/?errorCode=' + passedError)
            }
            else if(debug) {
                timedOutTimer && clearInterval(timedOutTimer)
                setIsValidated(true)
                FireAnalyticsEvent(EVENT_TYPES.SIGN_IN)
                login({
                    name: 'Lucy F Night',
                    id: '123-456-7890',
                    token: 'Test-Token',
                    avatar_url: avatar_url_dev
                })
            }
            else {
                FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_AUTHORIZE)
                navigate('/?errorCode=4')
                return
            }
        }

        const timedOutTimer = setInterval(() => {
            timedOutTimer && clearInterval(timedOutTimer)
            if(!isValidated) {
                FireAnalyticsEvent(EVENT_TYPES.ERROR_LOGIN_TIME_OUT)
                navigate('/?errorCode=1')
            }
        }, 10000)

        attemptValidation()
      }
    )

    return (
        <div className='Login-container'>
            <div className='flex-r flex-center full-width full-height'>
            <div className='Login-body-container flex-c'>
                    <div className='Login-body'>
                        <img src={logo} className='Login-logo' alt={DICT.LOGIN.logoAlt} title={DICT.LOGIN.logoAlt} ></img>
                        <div className='Login-text flex-c'>
                            <div className='Login-UCF'>{DICT.LOGIN.UCF}</div>
                            <div className='Login-Here'>{DICT.LOGIN.here}</div>
                        </div>
                    </div>
                    <div className='Login-Error'></div>
                </div>
                <div className='Login-Button-container'>
                    <Loader DICT={DICT} color='black' message={DICT.LOADER.validate}></Loader>
                </div>
                <div className='Login-Version'>
                    {`${DICT.LOGIN.version} ${version}
                        ${debug
                            ? `- ${DICT.LOGIN.local.toUpperCase()}`
                            : branch === BRANCHES.DEV
                                ? `- ${DICT.LOGIN.qa.toUpperCase()}`
                                : '' }`}
                </div>
            </div>
        </div>
    )
}
