import { debug, courseData, historyData, ucfhere_server } from '../config'
import { toJSON } from '../util'

/**
 * Attempt to retrieve user courses.
 *
 * @param {string} token - User bearer token
 * @returns {Promise}
 */
async function getSections(token) {

  if( debug ) {
    return courseData
  }

  const ucfHereSectionsUrl = `${ucfhere_server}/api/v1/sections`

  return fetch(ucfHereSectionsUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(toJSON)
}

/**
 * Get the 'history' array from the server
 * Leave argument empty to get all history.
 *
 * @param {string} token - user oauth bearer token
 * @param {string} courseId - course id
 * @param {string} sectionId - *OPTIONAL* section id
 * @returns {Promise}
 */
async function getHistory(token, sectionId) {

  if( debug ) {
    return historyData
  }

  const ucfHereServerHistoryUrl = `${ucfhere_server}/api/v1/history/${sectionId}`

  // If section_id is invalid, the server returns a 500 error

  return fetch(ucfHereServerHistoryUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(toJSON)
}

export const historyAPI = {
  getSections,
  getHistory,
}