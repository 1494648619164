import { useNavigate } from 'react-router-dom'

import { SCREEN_CHECK_IN, SCREEN_HISTORY, SCREEN_PROFILE, SELECT_KEYS } from '../../util'

import { ReactComponent as CheckInIcon } from '../../assets/qr-code.svg'
import { ReactComponent as HistoryIcon } from '../../assets/recently-viewed.svg'
import { ReactComponent as ProfileIcon } from '../../assets/user--avatar.svg'

import './footer-nav.scss'

/**
 * This component is at the bottom of the screen and allows user to navigate between pages
 */
export const FooterNav = ({ activeScreen, DICT }) => {

    const navigate = useNavigate()

    const linkAway = (newScreen) => {
        if(newScreen === activeScreen) {
            return
        }

        switch(newScreen) {
            case SCREEN_CHECK_IN:
                navigate('/auth/checkin')
                break
            case SCREEN_HISTORY:
                navigate('/auth/history')
                break
            case SCREEN_PROFILE:
                navigate('/auth/profile')
                break
            default:
                return
        }
    }

    return (
        <footer className='Footer-container'>
            <nav className='Footer-row wide-content-wrapper'>
                <section
                    aria-label={DICT.MAIN.checkIn}
                    id='check-in-btn'
                    tabIndex='0'
                    role='button'
                    className={`Footer-button ${activeScreen === SCREEN_CHECK_IN ? 'active' : ''}`}
                    onClick={() => linkAway(SCREEN_CHECK_IN)}
                    onKeyDown={(event) => { if(SELECT_KEYS.includes(event.key)) { linkAway(SCREEN_CHECK_IN)}}}
                    >
                    <div className='nav-content-container'>
                        <div className='Footer-icon'>
                            <CheckInIcon className='icon' alt='' title=''/>
                        </div>
                        <div id='checkInBtnLabel' className='Footer-text'>
                            {DICT.MAIN.checkIn}
                        </div>
                    </div>
                </section>
                <section
                    aria-label={DICT.MAIN.history}
                    id='history-btn'
                    tabIndex='0'
                    role='button'
                    className={`Footer-button ${activeScreen === SCREEN_HISTORY ? 'active' : ''}`}
                    onClick={() => linkAway(SCREEN_HISTORY)}
                    onKeyDown={(event) => { if(SELECT_KEYS.includes(event.key)) { linkAway(SCREEN_HISTORY)}}}
                    >
                    <div className='nav-content-container'>
                        <div className='Footer-icon'>
                            <HistoryIcon className='icon' alt='' title=''/>
                        </div>
                        <div id='historyBtnLabel' className='Footer-text'>
                            {DICT.MAIN.history}
                        </div>
                    </div>
                </section>
                <section
                    aria-label={DICT.MAIN.profile}
                    id='profile-btn'
                    tabIndex='0'
                    role='button'
                    className={`Footer-button ${activeScreen === SCREEN_PROFILE ? 'active' : ''}`}
                    onClick={() => linkAway(SCREEN_PROFILE)}
                    onKeyDown={(event) => { if(SELECT_KEYS.includes(event.key)) { linkAway(SCREEN_PROFILE)}}}
                    >
                    <div className='nav-content-container'>
                        <div className='Footer-icon'>
                            <ProfileIcon className='icon' alt='' title=''/>
                        </div>
                        <div id='profileBtnLabel' className='Footer-text'>
                            {DICT.MAIN.profile}
                        </div>
                    </div>
                </section>
            </nav>
        </footer>
    )
}
