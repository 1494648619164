import React from 'react'

import { ReactComponent as ZoomIn } from '../../assets/zoom-in.svg'
import { ReactComponent as ZoomOut } from '../../assets/zoom-out.svg'

import './zoom-scroller.scss'


/**
 * This component is used to show status when you can the QR code
 */

export function ZoomScroller ({ DICT, zoom, zoomMin, zoomMax, zoomStep, changeZoom }) {

    const zoomButtonStep = 1

    const zoomIn = () => {
        let tempZoom = parseInt(zoom) + parseInt(zoomButtonStep)
        if(tempZoom > zoomMax) {
            tempZoom = zoomMax
        }
        changeZoom(tempZoom)
    }

    const zoomOut = () => {
        let tempZoom = parseInt(zoom) - parseInt(zoomButtonStep)
        if(tempZoom < zoomMin) {
            tempZoom = zoomMin
        }
        changeZoom(tempZoom)
    }
    
    return (
        <div className='Zoom-container'>
            <div className='Zoom-icon-container' onClick={() => {zoomIn()}} alt={DICT.CHECKIN.zoomIn} title={DICT.CHECKIN.zoomIn}>
                <ZoomIn className='icon white' />
            </div>
            <div className='Zoom-selector-container'>
                <input
                    id='Zoom-selector'
                    tabIndex='0'
                    type='range' 
                    min={zoomMin}
                    max={zoomMax}
                    step={zoomStep}
                    className='slider'
                    value={zoom}
                    onChange={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        changeZoom(event.target.value)
                    }}
                />
            </div>
            <div className='Zoom-icon-container' onClick={() => {zoomOut()}} alt={DICT.CHECKIN.zoomOut} title={DICT.CHECKIN.zoomOut}>
                <ZoomOut className='icon white' />
            </div>
        </div>
    )
}
