import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { DESELECT_KEYS, SCREEN_HISTORY, SELECT_KEYS } from '../../util'
import { EVENT_TYPES, FireAnalyticsEvent } from '../../util/analytics'
import { useLocalStorage } from '../../util/useLocalStorage'
import { useAuth } from '../../util/useAuth'
import { historyAPI } from '../../api/history'

import { AttendanceItem } from '../../components/history-attendance-item/history-attendance-item'

import { FooterNav } from '../../components/footer-nav/footer-nav'
import { Loader } from '../../components/loader/loader'
import { ReactComponent as ExpandIcon } from '../../assets/chevron--right.svg'

import '../history/history.scss'

/**
 * This screen contains attendance information about a course
 * It shows a list of all the times attendance was taken
 */

export function HistorySectionScreen({ DICT }) {
    const { user, logout } = useAuth()
    const navigate = useNavigate()
    const { sectionId } = useParams()
    // Sections are read here, but set in the history screen
    // eslint-disable-next-line
    const [sections, setSections] = useLocalStorage('sections', null)
    // We search all sections to find the one we want or if it doesn't find it, returns null
    let section = sections.find((sec) => sec.section_id === Number(sectionId)) || null
    const [isHistoryLoading, setIsHistoryLoading] = useState(true)
    const [historyData, setHistoryData] = useState(null)

    const loadHistory = async () => {
        setHistoryData(null)
        // no section was found, we stop loading
        if (!section || !section.section_id) {
            setIsHistoryLoading(false)
            return
        }
        setIsHistoryLoading(true)
        try {
            const response = await historyAPI.getHistory(user.token, section.section_id)
            setIsHistoryLoading(false)
            if (!response || !response.history || response.history.length === 0) {
                setHistoryData(null)
            }
            else {
                FireAnalyticsEvent(EVENT_TYPES.HISTORY_SECTION, 'section_' + section.section_id)
                setHistoryData(response)
            }
        } catch (error) {
            console.error(error)
            FireAnalyticsEvent(EVENT_TYPES.ERROR_HISTORY_CONNECTION)
            setIsHistoryLoading(false)
            logout()
        }
    }


    const deselectSection = () => {
        section = null
        navigate('/auth/history')
    }

    useEffect(() => {
        if (historyData != null) {
            setIsHistoryLoading(false)
        }
    }, [historyData])

    useEffect(() => {
        if (!user.token) {
            logout()
            return
        }
        try {
            loadHistory()
        } catch (error) {
            console.error(error)
        }

        const el = document.getElementById('assistive-notification')
        if (el) el.innerHTML = DICT.HISTORY.sectionWelcome1 + ' ' + section?.course?.name + ' ' + DICT.HISTORY.sectionWelcome2 + ' ' + section?.section_name + ' ' + DICT.HISTORY.sectionWelcome3
    },
        /* eslint-disable react-hooks/exhaustive-deps */
        [])

    const sectionLabel = (DICT.HISTORY.sectionLabel1 !== '' ? DICT.HISTORY.sectionLabel1 + ' ' : '')
        + (section?.course?.name ? section.course.name + ' ' : '')
        + (DICT.HISTORY.sectionLabel2 !== '' ? DICT.HISTORY.sectionLabel2 + ' ' : '')
        + (section?.section_name ? section.section_name + ' ' : '')
        + (DICT.HISTORY.sectionLabel3 !== '' ? DICT.HISTORY.sectionLabel3 + ' ' : '')
        + (section?.course?.instructor?.name ? section.course.instructor.name + ' ' : '')
        + (DICT.HISTORY.sectionLabel4 !== '' ? DICT.HISTORY.sectionLabel4 : '')

    return (
    <div className='FullScreen' onKeyDown={(event) => { if (DESELECT_KEYS.includes(event.key)) { deselectSection() } }} >
        <div id='assistive-notification' className='Reader-Instructions' aria-live='polite'></div>
        <div className='FullContent'>
            <article aria-label={DICT.HISTORY.coursesBtnDesc} alt={DICT.HISTORY.coursesBtnDesc} title={DICT.HISTORY.coursesBtnDesc} tabIndex='0' id='deselect' role='button' className='Header-Button-container clickable flex-r' onClick={() => deselectSection()} onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key)) { deselectSection() } }}>
                <div className='flex-c flex-center clickable'><ExpandIcon className='icon Expand-Icon reversed' /></div>
                <label htmlFor='deselect' className='flex-c flex-center clickable'><div>{DICT.HISTORY.coursesBtnLabel}</div></label>
            </article>
            <main className='History-container PrimaryScreen'>
                <section className='flex-c full-width'>
                    {(section) ?
                        (<header role='heading' aria-level='1' aria-label={sectionLabel} className='List-Header' tabIndex='0'>
                            <article aria-hidden='true' tabIndex='-1' className='List-Header-Title wide-content-wrapper'>{section?.course?.name} - {section?.section_name}</article>
                            <article aria-hidden='true' tabIndex='-1' className='List-Header-Subtitle wide-content-wrapper'>{section?.course?.instructor?.name}</article>
                        </header>)
                        : ''
                    }

                    {(!section) ? (<><article className='Loader-container'><div aria-label={DICT.HISTORY.noSection} role='heading' aria-level='2' className='flex-c flex-center'><div>{DICT.HISTORY.noSection}</div></div></article>
                        <article role='button' aria-label={DICT.HISTORY.coursesBtnDesc} tabIndex='0' className='Button-container' onKeyDown={(event) => { if (SELECT_KEYS.includes(event.key)) { deselectSection() } }}><button aria-hidden='true' className='button-rounded gold' onClick={() => deselectSection()}>{DICT.HISTORY.coursesBtnDesc}</button></article>
                    </>) :
                        (isHistoryLoading) ? (<article className='Loader-container disabled'><Loader DICT={DICT} message={DICT.LOADER.history} color='black' /></article>) :
                            (!historyData || !historyData.history || historyData.history.length === 0)
                                // Without history, the attendace's list is empty
                                ? (<><article className='Loader-container'><div role='heading' aria-level='2' className='flex-c flex-center'><div>No History Found for<br /><br /></div><div className='bold'>{section?.course?.name} - {section?.section_name}</div></div></article>
                                    <article className='Button-container'><button className='button-rounded gold' onClick={() => loadHistory()}>{DICT.HISTORY.refreshHistory}</button></article>
                                </>)
                                // With history data, render that list
                                : (<section className={`flex-c full-width ${DICT.DIRECTION || 'ltr'}`}>

                                    {historyData.history.map((historyEntry, index) => {
                                        return (
                                            <AttendanceItem
                                                key={index}
                                                timeStamp={historyEntry.timestamp}
                                                graded={historyEntry.session.state}
                                                state={historyEntry.state}
                                                override={historyEntry.override}
                                                DICT={DICT} />
                                        )
                                    })}
                                </section>)
                    }
                </section>
            </main>
        </div>
        <footer className='FooterNav'>
            <FooterNav activeScreen={SCREEN_HISTORY} DICT={DICT}></FooterNav>
        </footer>
    </div>
    )

}
