import React from 'react'
import { useNavigate } from 'react-router-dom'

import './feedback.scss'

/**
 * This screen renders after the user scanned the QR Code
 * It renders the correct feedback response
 */

export function FeedbackScreen ({DICT, scanResponseCode, scanCourseData, scanUserData, scanTimestamp, resetCapture}) {
    const navigate = useNavigate()

    let message = ''

    // Determines whether SUCCESS or ERROR is shown at the top of the screen
    const SUCCESS_CODES = [
        100,
        200,
    ]

    // Determines whether the user is shown an 'OK' button or a 'TRY AGAIN' button
    const NO_RETRY_CODES = [
        100,
        200,
        404,
    ]

    switch(scanResponseCode) {
        case 100:
            message = DICT.FEEDBACK.message100
            break
        case 200:
            message = DICT.FEEDBACK.message200
            break
        case 300:
            message = DICT.FEEDBACK.message300
            break
        case 404:
            message = DICT.FEEDBACK.message404
            break
        // 400 errors could either be because the session no longer exists
        //     OR the codes are for a different session
        //     OR the codes sent were not consecutive
        default:
            message = DICT.FEEDBACK.messageDefault
    }

    return (
        <div className={`Feedback-container PrimaryScreen wide-content-wrapper`}>
            <div className='full-width flex-space flex-c sans-status-bar'>
                <div className='flex-c flex-grow full-width'>
                    { SUCCESS_CODES.includes(scanResponseCode)
                        ? (<div className='text-title green'>{DICT.FEEDBACK.headerSuccess}</div>)
                        : (<div className='text-title red'>{DICT.FEEDBACK.headerError}</div>)
                    }
                    <div className='text'>{message}</div>
                    { scanCourseData != {} || scanUserData != {}  // eslint-disable-line
                    ? ( <div className='Notice-container'>
                            { scanUserData?.user_name
                              ? <div className='text-item'>
                                  <div className='text-subtitle'>{scanUserData.user_name}</div>
                              </div>
                              : '' }
                            { scanCourseData?.course_name
                              ? <div className='text-item'>
                                  <div className='text-subtitle'>{DICT.FEEDBACK.courseLabel}</div>
                                  <div className='text'>{scanCourseData.course_name || ''}</div>
                              </div>
                              : '' }
                            { scanCourseData?.instructor_name
                              ? <div className='text-item'>
                                  <div className='text-subtitle'>{DICT.FEEDBACK.instructorLabel}</div>
                                  <div className='text'>{scanCourseData.instructor_name || ''}</div>
                              </div>
                              : '' }
                            <div className='text-item'>
                                <div className='text-subtitle'>{DICT.FEEDBACK.timeLabel}</div>
                                <div className='text'>{new Date(scanTimestamp).toLocaleString('en-us')}</div>
                            </div>
                        </div> )
                    : '' }
                </div>
                { NO_RETRY_CODES.includes(scanResponseCode)
                ? (<div className='flex-c flex-stiff full-width'>
                        <button className='button-rounded green' onClick={() => navigate('/auth/history')}>{DICT.FEEDBACK.btnOK}</button>
                    </div>)
                : (<div className='flex-c flex-stiff full-width'>
                        <button className='button-rounded green' onClick={() => resetCapture()}>{DICT.FEEDBACK.btnTryAgain}</button>
                    </div>)
                }
            </div>
        </div>
    )
}
