import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { authAPI } from '../api/auth'
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const [sessionToken, setSessionToken] = useLocalStorage('sessionToken', null)
  const navigate = useNavigate()

  // When a new user is attempting to sign in, we generate a unique sessionToken, which is
  // returned by Canvas after the authentication is complete.
  // This addresses a security vulnerability mentioned in:
  // https://canvas.instructure.com/doc/api/file.oauth_endpoints.html#get-login-oauth2-auth
  const generateSessionToken = (tokenLength = 12) => {
    const tokenChars = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789'
    const tokenCharsLength = tokenChars.length

    let tempToken = ''
    while(tempToken.length < tokenLength){
      tempToken += tokenChars.charAt(Math.floor(Math.random() * tokenCharsLength))
    }

    setSessionToken(tempToken)
    return tempToken
  }

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data)
    navigate('/auth/checkin')
  }

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      if(user?.token) {
        await authAPI.revokeToken(user.token)
      }
    } catch (error) {
      console.error(error)
    }
    setUser(null)
    navigate('/', { replace: true })
  }

  const clearCookies = () => {
    setUser(null)
    setSessionToken(null)
  }

  const value = useMemo(
    () => ({
      user,
      sessionToken,
      generateSessionToken,
      login,
      logout,
      clearCookies
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [user]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}